import * as React from "react"

import fsgLogo from "icons/svg/fsg_logo.svg"

export default (props: any): JSX.Element => {
	const { isAdmin, lang } = props
	return (
		<header className="section__header">
			<div className="container">
				<div className="row">
					<div className="col-6 header__col">
						<a href={`/${lang ?? ""}`}>
							<img
								draggable={false}
								className="icon logo logo__mobile"
								src={fsgLogo}
								alt=""
							/>
						</a>
					</div>
					<div className="col-6 d-flex justify-content-end header__col">
						<button
							className="button button--light lang__switcher link"
							type="button"
							onClick={() =>
								(location.href = `${isAdmin === true ? "/admin" : ""}/`)
							}
						>
							DE
						</button>
						<button
							className="button button--light lang__switcher link"
							type="button"
							onClick={() =>
								(location.href = `${isAdmin === true ? "/admin" : ""}/en`)
							}
						>
							EN
						</button>
					</div>
				</div>
			</div>
		</header>
	)
}
