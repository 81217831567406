import * as React from "react"
import ContentHeader from "../components/content-header"
import Footer from "../components/footer"

export default (): JSX.Element => {
	return (
		<div className="container">
			<ContentHeader />
			<br />
			<br />
			<div
				dangerouslySetInnerHTML={{
					__html: `<h1>Datenschutzerklärung</h1>
			<p>
				Wir verarbeiten Ihre personenbezogenen Daten ausschließlich im Rahmen der
				gesetzlichen Datenschutzbestimmungen, insbesondere der
				Datenschutzgrundverordnung (DS-GVO) und des Bundesdatenschutzgesetzes
				(BDSG-neu).
			</p>
			<p>
				Im Folgenden möchten wir Sie daher über die Verarbeitung (z.B. Erhebung,
				Speicherung, Weiterleitung) personenbezogener Daten bei der Nutzung unserer
				Webseite und in weiteren, spezifischen Verarbeitungssituationen informieren.
				Unter personenbezogenen Daten versteht die Datenschutzgrundverordnung gemäß
				Art. 4 Nr. 1 DS-GVO
			</p>
			<p style="font-style: italic;">
				"Alle Informationen, die sich auf eine identifizierte oder identifizierbare
				natürliche Person beziehen; als identifizierbar wird eine natürliche Person
				angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer
				Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer
				Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert
				werden kann, die Ausdruck der physischen, physiologischen, genetischen,
				psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser
				natürlichen Person sind." 
			</p>
			<br />
			<p style="border-top: 1px solid #004899; font-size: 1px;">.</p>
			<br />

			<h2>Inhaltsverzeichnis</h2>
			<p>
				<strong>
					Datenschutzerklärung der Flughafen Stuttgart GmbH für die Webseiten-Nutzer
				</strong>
			</p>
			<p>
				<strong>
					<a href="#1-0">I   Name und Anschrift der Verantwortlichen</a>
				</strong>
			</p>
			<p>
				<strong>
					<a href="#2-0">
						II   Name und Anschrift des betrieblichen Datenschutzbeauftragten
					</a>
				</strong>
			</p>
			<p>
				<strong>
					<a href="#3-0">III   Allgemeines zur Datenverarbeitung</a>
				</strong>
			</p>
			<ol>
				<li>
					<a href="#3-1">Umfang der Verarbeitung der personenbezogenen Daten</a>
				</li>
				<li>
					<a href="#3-2">
						Rechtsgrundlage für die Verarbeitung personenbezogener Daten
					</a>
				</li>
				<li>
					<a href="#3-3">
						Empfänger oder Kategorien von Empfänger, denen die Daten mitgeteilt
						werden können
					</a>
				</li>
				<li>
					<a href="#3-4">Dauer der Speicherung, Löschung</a>
				</li>
			</ol>
			<p>
				<strong>
					<a href="#4-0">
						IV   Erhebung und Speicherung personenbezogener Daten durch die Flughafen
						Stuttgart GmbH sowie Art und Zweck deren Verwendung
					</a>
				</strong>
			</p>
			<ol>
				<li>
					<a href="#4-1">
						Besuch der Webseite www.flughafen-stuttgart.de /
						www.stuttgart-airport.com{" "}
					</a>
				</li>
				<li>
					<a href="#4-2">Verwendung von Cookies</a>
				</li>
				<li>
					<a href="#4-3">
						Einsatz von externen Diensten mit technisch nicht notwendigen Cookies
					</a>
				</li>
				<li>
					<a href="#4-4">Kontaktformulare und E-Mail-Kontakt</a>
				</li>
				<li>
					<a href="#4-5">Newsletter</a>
				</li>
				<li>
					<a href="#4-6">
						Social-Media Profile und Kommentarfunktionen auf unserer Webseite
					</a>
				</li>
				<li>
					<a href="#4-7">Flugstatusbenachrichtigung per E-Mail</a>
				</li>
				<li>
					<a href="#4-8">
						Buchungsmöglichkeiten und sonstige Funktionen unserer Webseite
					</a>
				</li>
				<li>
					<a href="#4-9">Abonnement des Airport-Magazins Flugblatt</a>
				</li>
			</ol>
			<p>
				<strong>
					<a href="#5-0">V   Betroffenenrechte</a>
				</strong>
			</p>
			<ol>
				<li>
					<a href="#5-1">Auskunftsrecht gemäß Art. 15 DS-GVO</a>
				</li>
				<li>
					<a href="#5-2">Recht auf Berichtigung gemäß Art. 16 DS-GVO</a>
				</li>
				<li>
					<a href="#5-3">
						Recht auf Löschung ("Vergessenwerden") gemäß Art. 17 DS-GVO
					</a>
				</li>
				<li>
					<a href="#5-4">
						Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DS-GVO
					</a>
				</li>
				<li>
					<a href="#5-5">Recht auf Datenübertragbarkeit gemäß Art. 20 DS-GVO</a>
				</li>
				<li>
					<a href="#5-6">Widerspruchsrecht gemäß Art. 21. DS-GVO</a>
				</li>
				<li>
					<a href="#5-7">
						Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
						gemäß Art. 7 Abs. 3 DS-GVO
					</a>
				</li>
				<li>
					<a href="#5-8">
						Automatisierte Entscheidung im Einzelfall einschließlich Profiling gemäß
						Art. 22 DS-GVO
					</a>
				</li>
				<li>
					<a href="#5-9">
						Recht auf Beschwerde bei einer Aufsichtsbehörde gemäß Art. 77 DS-GVO
					</a>
				</li>
			</ol>
			<p>
				<strong>
					<a href="#6-0">VI   Datensicherheit</a>
				</strong>
			</p>
			<p>
				<strong>
					<a href="#7-0">VII   Rückfragen</a>
				</strong>
			</p>
			<p>
				<strong>
					<a href="#8-0">VIII   Stand dieser Datenschutzerklärung</a>
				</strong>
				<br />
				<br />
			</p>
			<p style="border-top: 1px solid #004899; font-size: 1px;">.</p>
			<p> </p>

			<p style="font-size: 1px;">.</p>
			<h2 id="1-0" style="margin: 0px 0 6px 0;">
				I   Name und Anschrift der Verantwortlichen
			</h2>
			<p style="padding-left: 30px;">
				Flughafen Stuttgart GmbH
				<br />
				Flughafenstraße 32
				<br />
				70629 Stuttgart
			</p>
			<p style="padding-left: 30px;">
				Postfach 23 04 61
				<br />
				70624 Stuttgart
			</p>
			<p style="padding-left: 30px;">
				Telefon: +49 711 948-0
				<br />
				Telefax: +49 711 948-2241
				<br />
				E-Mail:{" "}
				<a href="mailto:info@stuttgart-airport.com">info@stuttgart-airport.com</a>
			</p>
			<p style="padding-left: 30px;">
				<strong>Gesetzliche Vertreter:</strong>
				<br />
				Geschäftsführung:
			</p>
			<p style="padding-left: 30px;">
				Walter Schoefer (Sprecher) <br/>
				Ulrich Heppe
			</p>
			<h2 id="2-0" style="margin: 30px 0 6px 0;">
				II   Name und Anschrift des betrieblichen Datenschutzbeauftragten
			</h2>
			<p style="padding-left: 30px;">
				Den betrieblichen Datenschutzbeauftragten der Flughafen Stuttgart GmbH
				erreichen Sie postalisch oder per E-Mail.
			</p>
			<p style="padding-left: 30px;">
				<strong>Per Post:</strong>
				<br />
				Flughafen Stuttgart GmbH
				<br />
				Datenschutzbeauftragter
				<br />
				Flughafenstraße 32
				<br />
				70629 Stuttgart
			</p>
			<p style="padding-left: 30px;">
				<strong>Per E-Mail:</strong> <br />
				<a href="mailto:DSB@stuttgart-airport.com">DSB@stuttgart-airport.com</a>
			</p>
			<h2 id="3-0" style="margin: 30px 0 6px 0;">
				III   Allgemeines zur DatenverarbeitunG
			</h2>
			<ol>
				<li id="3-1">
					<strong>Umfang der Verarbeitung der personenbezogenen Daten</strong>
					<br />
					<br />
					Eine Verarbeitung von personenbezogenen Daten unserer Nutzer findet
					grundsätzlich nur statt,
					<br />
					<br />
					<ul>
						<li>
							soweit dies zur Bereitstellung einer funktionsfähigen Webseite und zur
							Darstellung der diesbezüglichen Inhalte erforderlich ist;
						</li>
						<li>
							und/oder soweit wir als Verantwortliche vom Nutzer eine Einwilligung
							gemäß Art. 6 Abs. 1 lit. a DS-GVO eingeholt haben;
						</li>
						<li>
							und/oder soweit die Verarbeitung der personenbezogenen Daten aufgrund
							eines gesetzlichen Erlaubnistatbestandes (Art. 6 Abs. 1 lit. b – f
							DS-GVO) erlaubt ist.
						</li>
					</ul>
				</li>
				<br />
				<li id="3-2">
					<strong>
						Rechtsgrundlage für die Verarbeitung personenbezogener Daten
					</strong>
					<br />
					<br />
					Soweit wir für die Verarbeitung von personenbezogenen Daten eine
					Einwilligung des Betroffenen einholen, dient Art. 6 Abs. 1 lit. a DS-GVO
					als Rechtsgrundlage.
					<br />
					<br />
					Bei der Verarbeitung personenbezogener Daten, die zur Erfüllung eines
					Vertrages, dessen Vertragspartei der Betroffene ist, erforderlich ist,
					dient Art. 6 Abs. 1 lit. b DS-GVO als Rechtsgrundlage. Das gilt auch für
					Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen
					erforderlich sind.
					<br />
					<br />
					Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
					rechtlichen Verpflichtung, der die Flughafen Stuttgart GmbH unterliegt,
					erforderlich ist, dient Art. 6 Abs. 1 lit. c DS-GVO als Rechtsgrundlage.
					<br />
					<br />
					Ist die Verarbeitung zur Wahrung eines berechtigten Interesses der
					Flughafen Stuttgart GmbH oder eines Dritten erforderlich, so dient Art. 6
					Abs. 1 lit. f DS-GVO als Rechtsgrundlage für die Verarbeitung. <br />
					<br />
				</li>
				<li id="3-3">
					<strong>
						Empfänger oder Kategorien von Empfänger, denen die Daten mitgeteilt
						werden können
					</strong>
					<br />
					<br />
					<ul>
						<li>
							Gerichte, Behörden oder andere staatliche Stellen, soweit eine
							rechtliche Verpflichtung besteht
						</li>
						<li>
							Externe Stellen, soweit dies zur Erfüllung der jeweils genannten Zwecke
							erforderlich ist
						</li>
						<li>
							Externe Auftragnehmer im Rahmen der Vorschriften des Art. 28 DS-GVO
						</li>
						<li>
							Sonstige Kooperationspartner deren Beteiligung zur Erbringung der
							Leistung erforderlich ist 
						</li>
					</ul>
				</li>
				<br />
				<li id="3-4">
					<strong>Dauer der Speicherung, Löschung</strong>
					<br />
					<br />
					Die Speicherung der personenbezogenen Daten erfolgt nur so lange, wie dies
					zur Erreichung der jeweils genannten Zwecke erforderlich ist oder gemäß der
					gesetzlich vorgesehenen Aufbewahrungsfristen.
				</li>
			</ol>
			<h2 id="4-0" style="margin: 30px 0 6px 0;">
				IV   Erhebung und Speicherung personenbezogener Daten durch die Flughafen
				Stuttgart GmbH sowie Art und Zweck deren Verwendung
			</h2>
			<ol>
				<li id="4-1">
					<strong>
						Besuch der Webseite www.flughafen-stuttgart.de /
						www.stuttgart-airport.com
						<br />
					</strong>
					<p>
						Bei der bloß informatorischen Nutzung (reines Betrachten und Lesen)
						unserer Webseite www.flughafen-stuttgart.de / www.stuttgart-airport.com,
						also, wenn Sie sich nicht mit uns (zum Beispiel über ein Kontaktformular)
						in Verbindung setzen und auch keinerlei anderweitige Informationen an uns
						übermitteln, erheben wir nur die personenbezogenen Daten, die
						automatisiert übermittelt werden, oder die Ihr Browser – je nach Endgerät
						und je nach Konfiguration des Clients – an unseren Server übermittelt.
					</p>
					<p>
						Diese Informationen werden temporär in einem sog. Logfile gespeichert. In
						diesem Fall werden nur Daten erhoben, die unser System erfasst. Hierbei
						werden folgende Daten erhoben:
					</p>
					<ul>
						<li>IP-Adresse</li>
						<li>Datum und Uhrzeit des Zugriffs</li>
						<li>Name und URL der abgerufenen Seite</li>
						<li>Webseite, von der aus der Zugriff erfolgt (sog. Referrer-URL)</li>
						<li>Informationen über den Browsertyp und die verwendete Version</li>
						<li>Betriebssystem und dessen Oberfläche</li>
						<li>Sprache und Version der Browsersoftware</li>
						<li>Internet-Service-Provider des Nutzers</li>
					</ul>
					<p>
						Rechtsgrundlage für die vorübergehende Speicherung dieser Daten und der
						Logfiles ist Art. 6 Abs. 1 lit. f DS-GVO. Die Speicherung der Daten in
						Logfiles erfolgt, um die Funktionsfähigkeit der Webseite sicherzustellen
						und im Falle von Fehlern oder Problemen im Netzwerk eine adäquate Analyse
						durchzuführen und hiermit die Sicherheit unserer informationstechnischen
						Systeme sicherzustellen. Eine Auswertung dieser Daten zu anderen Zwecken
						findet nicht statt. Darin liegt gleichzeitig unser berechtigtes
						Interesse, welches wir Ihnen als Nutzer gemäß Art. 13 Abs. 1 lit. d
						DS-GVO hiermit mitteilen.
					</p>
					<p>
						Die Logfiles und somit auch die darin gespeicherten Daten werden nach 14
						Tagen gelöscht. Eine darüberhinausgehende Speicherung der Logfiles
						erfolgt nicht. 
						<br />
						<br />
					</p>
				</li>
				<li id="4-2">
					<strong>
						Verwendung von Cookies
						<br />
					</strong>
					<p>
						Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um
						Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
						Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine
						Webseite auf, so können Cookies auf dem Betriebssystem des Nutzers
						gespeichert werden. Die gespeicherten Cookies enthalten eine
						charakteristische Zeichenfolge, die eine eindeutige Identifizierung des
						Browsers beim erneuten Aufrufen der Webseite ermöglicht.
					</p>
					<p>
						Wir setzen auf unserer Webseite zwei Arten von Cookies ein. Technisch
						notwendige Cookies und technisch nicht notwendige Cookies.
					</p>
					<ul>
						<li>
							<strong>Technisch notwendige Cookies</strong>
							<br />
							Technisch notwendige Cookies sind solche, um unsere Webseite
							nutzerfreundlicher zu gestalten. Einige Elemente unserer Webseite
							erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel
							identifiziert werden kann. Ohne diese Cookies ist es uns nicht möglich,
							Ihnen bestimmte Funktionalitäten unserer Webseite zu liefern, zum
							Beispiel die Spracheinstellungen unserer Webseite.
							<br />
							<br />
						</li>
						<li>
							<strong>Technisch nicht notwendige Cookies</strong>
							<br />
							Darüber hinaus verwenden wir auf unserer Webseite auch Cookies, die
							nicht technisch notwendig sind und eine Analyse des Surfverhaltens der
							Nutzer ermöglichen, wie zum Beispiel die Feststellung der Häufigkeit
							von Seitenaufrufen, oder die Inanspruchnahme von Webseiten-Funktionen
							(siehe auch <a href="#4-3a">Ziffer 3</a> dieser Datenschutzerklärung).
							<br />
							<br />
						</li>
					</ul>
					Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten bei
					Cookies ist Art. 6 Abs. 1 lit. f DS-GVO. <br />
					<br />
					Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung
					unserer Webseite für den Nutzer zu vereinfachen. Einige Funktionen unserer
					Webseite können ohne den Einsatz von Cookies nicht angeboten werden.
					Hierbei handelt es sich um Funktionen, für welche es erforderlich ist, dass
					der Browser auch nach einem Seitenwechsel wiedererkannt wird. Ein Beispiel
					hierfür ist zum Beispiel die Übernahme der ausgewählten Spracheinstellung.{" "}
					<br />
					<br />
					Der Zweck der Verwendung technisch nicht notwendiger Analyse-Cookies ist,
					die Qualität unserer Webseite und ihrer Inhalte zu verbessern. Durch die
					Analyse-Cookies erfahren wir, wie die Webseite genutzt wird und können
					hierdurch die Funktionalitäten und Inhalte unserer Webseite stetig
					verbessern.
					<br />
					<br />
					Darin liegt unser berechtigtes Interesse, welches Ihnen als Nutzer gemäß
					Art. 13 Abs. 1 lit. d DS-GVO hiermit mitteilen.
					<br />
					<br />
					Die meisten Browser akzeptieren Cookies automatisch. Die Cookies werden
					dann auf dem Endgerät des Nutzers gespeichert und von diesem an uns
					übermittelt. Sie als Nutzer haben daher selbst die volle Kontrolle über die
					Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem
					Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder
					einschränken. Cookies, die bereits gespeichert worden sind, können
					jederzeit gelöscht werden. Die vollständige Deaktivierung von Cookies kann
					allerdings dazu führen, dass Sie nicht alle Funktionen unserer Webseite
					vollumfänglich nutzen können. 
					<br />
					<br />
				</li>
				<li id="4-3">
					<strong>
						Einsatz von externen Diensten mit technisch nicht notwendigen Cookies
						<br />
					</strong>
					<br />
					<ol>
						<li style="list-style-type: lower-latin;">
							<p id="4-3a">
								<strong>Google-Analytics</strong>
							</p>
							<p>
								Unsere Webseite benutzt Google Analytics, einen Webanalysedienst der
								Google Inc. ("Google"). Google Analytics verwendet ebenfalls
								"Cookies", also Textdateien, die auf Ihrem Computer gespeichert
								werden und die eine Analyse der Benutzung der Webseite durch Sie
								ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre
								Benutzung dieser Webseite werden in der Regel an einen Server von
								Google in den USA übertragen und dort gespeichert. Die im Rahmen von
								Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht
								mit anderen Daten von Google zusammengeführt. Unsere Webseite
								verwendet Google Analytics mit der Erweiterung "_anonymizeIp()".
								Dadurch werden IP-Adressen gekürzt weiterverarbeitet. Nur in
								Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
								in den USA übertragen und dort gekürzt. In unserem Auftrag wird
								Google diese Informationen benutzen, um Ihre Nutzung der Webseite
								auszuwerten, um Reports über die Webseite-Aktivitäten
								zusammenzustellen und um weitere mit der Nutzung der Webseite und der
								Internetnutzung verbundene Dienstleistungen gegenüber uns zu
								erbringen.
							</p>
							<p>
								Rechtsgrundlage für die Verarbeitung personenbezogener Daten ist Art.
								6 Abs. 1 lit. f DS-GVO, sowie eine Vereinbarung zur
								Auftragsverarbeitung im Sinne des Art. 28 DS-GVO. Wir nutzen Google
								Analytics, um die Nutzung unserer Webseite analysieren und regelmäßig
								verbessern zu können. Über die gewonnenen Statistiken können wir
								unser Angebot verbessern und für Sie als Nutzer interessanter
								ausgestalten. Darin liegt gleichzeitig unser berechtigtes Interesse,
								welches wir Ihnen als Nutzer gemäß Art. 13 Abs. 1 lit. d DS-GVO
								hiermit mitteilen.
							</p>
							<p>
								Cookies, die von Google-Analytics bereits gesetzt wurden, können von
								Ihnen als Nutzer jederzeit über Ihren Internetbrowser gelöscht
								werden. Die personenbezogenen Daten, die Google-Analytics erhebt,
								werden nach 14 Monaten gelöscht oder anonymisiert.
							</p>
							<p>
								Sie können die Speicherung der Cookies durch eine entsprechende
								Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
								darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
								Funktionen unserer Webseite vollumfänglich nutzen können. Sie können
								darüber hinaus die Erfassung der durch das Cookie erzeugten und auf
								Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an
								Google sowie die Verarbeitung dieser Daten durch Google verhindern,
								indem Sie das unter dem folgenden Link verfügbare Browser-Plug-in
								herunterladen und installieren: <br />
								<a
									rel="noopener"
									href="http://tools.google.com/dlpage/gaoptout?hl=de"
									target="_blank"
								>
									http://tools.google.com/dlpage/gaoptout?hl=de
								</a>
							</p>
							<p>
								Google ist nach dem Privacy-Shield-Abkommen zertifiziert und bietet
								hierdurch eine Garantie, europäisches Datenschutzrecht und somit die
								DS-GVO, einzuhalten.
							</p>
							<p>
								Mehr Informationen hierzu finden Sie hier:
								<br />
								<a
									rel="noopener"
									href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAl&amp;status=active"
									target="_blank"
								>
									https://www.privacyshield.gov/participant?id=a2zt000000001L5AAl&amp;status=active
								</a>
								<br />
								<a
									rel="noopener"
									href="https://www.privacyshield.gov/EU-US-Framework"
									target="_blank"
								>
									https://www.privacyshield.gov/EU-US-Framework
								</a>
							</p>
							<p>
								Nutzungsbedingungen von Google-Analytics:
								<br />
								<a
									rel="noopener"
									href="http://www.google.com/analytics/terms/de.html"
									target="_blank"
								>
									http://www.google.com/analytics/terms/de.html
								</a>
							</p>
							<p>
								Übersicht zum Datenschutz: <br />
								<a
									rel="noopener"
									href="http://www.google.com/intl/de/analytics/learn/privacy.html"
									target="_blank"
								>
									http://www.google.com/intl/de/analytics/learn/privacy.html
								</a>
							</p>
							<p>
								Datenschutzerklärung:
								<br />
								<a
									rel="noopener"
									href="http://www.google.de/intl/de/policies/privacy"
									target="_blank"
								>
									http://www.google.de/intl/de/policies/privacy
								</a>
							</p>
							<p>
								Eine genauere Erläuterung zu Google-Analytics finden Sie unter:
								<br />
								<a
									rel="noopener"
									href="https://www.google.com/intl/de_de/analytics"
									target="_blank"
								>
									https://www.google.com/intl/de_de/analytics
								</a>
							</p>
						</li>
						<li style="list-style-type: lower-latin;">
							<strong>Google-Maps</strong>
							<br />
							<p>
								Auf dieser Webseite nutzen wir das Angebot von Google Maps. Dadurch
								können wir Ihnen interaktive Karten direkt in der Webseite anzeigen
								und ermöglichen Ihnen die komfortable Nutzung der Karten-Funktion.
							</p>
							<p>
								Durch den Besuch auf der Webseite erhält Google die Information, dass
								Sie die entsprechende Unterseite unserer Webseite aufgerufen haben.
								Zudem werden personenbezogene Daten (so zum Beispiel Ihre IP-Adresse)
								an Google übermittelt. Dies erfolgt unabhängig davon, ob ein Google
								Nutzerkonto besteht, über das Sie eingeloggt sind, oder ob kein
								Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre
								Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem
								Profil bei Google nicht wünschen, müssen Sie sich vor dem Besuch
								unserer Webseite ausloggen. Google speichert Ihre Daten als
								Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung
								und/oder bedarfsgerechten Gestaltung seiner Webseite. Eine solche
								Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer)
								zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des
								sozialen Netzwerks über Ihre Aktivitäten auf unserer Webseite zu
								informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung
								dieser Nutzerprofile, wobei Sie sich zur Ausübung des
								Widerspruchsrechts an Google richten müssen.
							</p>
							<p>
								Weitere Informationen zur Verarbeitung von personenbezogenen Daten
								durch Google-Maps erhalten Sie in der Datenschutzerklärung von
								Google. Dort erhalten Sie auch weitere Informationen zu Ihren
								diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze
								Ihrer Privatsphäre.
							</p>
							<p>
								Datenschutzerklärung:
								<br />
								<a
									rel="noopener"
									href="http://www.google.de/intl/de/policies/privacy"
									target="_blank"
								>
									http://www.google.de/intl/de/policies/privacy
								</a>
							</p>
							<p>
								Google verarbeitet Ihre personenbezogenen Daten auch in den USA und
								hat sich dem EU-US Privacy Shield unterworfen: <br />
								<a
									rel="noopener"
									href="https://www.privacyshield.gov/EU-US-Framework"
									target="_blank"
								>
									https://www.privacyshield.gov/EU-US-Framework
								</a>
								.
							</p>
							<p>
								Weitere Informationen finden Sie zudem in den Nutzungsbedingungen
								unter: <br />
								<a
									rel="noopener"
									href="https://www.google.com/intl/de_US/help/terms_maps.html"
									target="_blank"
								>
									https://www.google.com/intl/de_US/help/terms_maps.html
								</a>
								.
							</p>
							<a
								rel="noopener"
								href="https://www.google.com/intl/de_de/analytics"
								target="_blank"
							>
								<br />
							</a>
						</li>
						<li style="list-style-type: lower-latin;">
							<strong>affilinet Tracking Cookies</strong>
							<br />
							<br />
							Unsere Webseite nutzt ein affilinet-Netzwerk, betrieben von der
							affilinet GmbH, Sapporbogen 6-8, 80637 München. Zur korrekten Erfassung
							von Sales und/oder Leads setzt affilinet auf Ihrem Computer ein Cookie.
							Dieses Cookie wird von der Domain partners.webmaster-plan.com bzw.
							banners.webmasterplan.com gesetzt. Dieses Cookie entspricht den jeweils
							gültigen Datenschutzrichtlinien. Die von affilinet verwendeten Cookies
							werden in der Standardeinstellung Ihres Internet-Browsers
							akzeptiert.Falls Sie die Speicherung dieser Cookies nicht wünschen,
							deaktivieren Sie bitte die Annahme der Cookies der entsprechenden
							Domänen in Ihrem Internet Browser. affilinet Tracking Cookies speichern
							keinerlei personenbezogenen Daten, sondern lediglich die ID des
							vermittelnden Partners sowie die Ordnungsnummer des vom Besucher
							angeklickten Werbemittels (Banner, Textlink o. Ä.), die zur
							Zahlungsabwicklung benötigt werden. Die Partner ID dient bei Abschluss
							einer Transaktion dazu, die an den vermittelnden Partner zu zahlende
							Provision diesem auch zuordnen zu können. Details hierzu können Sie den
							Cookie-Richtlinien
							(https://www.affili.net/de/knowledge-zone/ueber-cookies) der affilinet
							GmbH entnehmen.
							<br />
							<br />
							<br />
						</li>
					</ol>
				</li>
				<li id="4-4">
					<strong>
						Kontaktformulare und E-Mail-Kontakt
						<br />
					</strong>
					<p>
						Sie können mit uns per E-Mail an{" "}
						<a href="mailto:info@stuttgart-airport.com">
							info@stuttgart-airport.com
						</a>{" "}
						in Kontakt treten. In diesem Fall werden die mit der E-Mail übermittelten
						personenbezogenen Daten zu Ihrer Person gespeichert.
					</p>
					<p>
						Auf unserer Webseite ist zudem ein Kontaktformular vorhanden, welches für
						die elektronische Kontaktaufnahme zur Flughafen Stuttgart GmbH
						hinsichtlich verschiedener Themenbereiche genutzt werden kann.
					</p>
					<p>
						Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein
						Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre
						E-Mail-Adresse, Ihr Name, Ihr Anliegen und bei Kontaktaufnahme zum
						Lärmschutzbeauftragen Ihren PLZ und Wohnort) von uns gespeichert, um Ihr
						Anliegen angemessen zu beantworten. Darüber hinaus wird eine Kopie des
						Kontaktformulars und somit auch Ihrer Eingaben, sowie der Zeitpunkt Ihrer
						Kontaktaufnahme in unserem Content-Management-System ("CMS") gespeichert.
						Die Daten aus dem Kontaktformular werden verschlüsselt an unseren
						Webserver übertragen.
					</p>
					<p>
						Abhängig davon, zu welcher Thematik Sie mit uns, per E-Mail oder mittels
						Kontaktformular, in Kontakt treten, erfolgt eine Übermittlung Ihrer Daten
						und Ihres Anliegens an Dritte. Eine Übermittlung an Dritte findet nur
						statt, soweit dies zur Aufklärung und zur Beantwortung der Kontaktanfrage
						erforderlich ist.
						<br />
						<br />
						Eine Übermittlung an Dritte erfolgt dabei insbesondere in folgenden
						Fällen:
					</p>
					<ul>
						<li>
							<p>
								<strong>
									Bei Feedback/Fragen zum Boarding (z.B.: Check-In, Gepäckermittlung,
									Boarding Support)
								</strong>
								<br />
								Zur Aufklärung des Sachverhalts erfolgt eine Übermittlung Ihrer
								personenbezogenen Daten und Ihrer Anfrage gegebenenfalls an:
							</p>
							<p style="font-style: italic;">
								Stuttgart Airport Ground Handling GmbH (SAG)
								<br />
								Postfach 23 02 70
								<br />
								70622 Stuttgart
							</p>
							<p>Und/oder an:</p>
							<p style="font-style: italic;">
								Stuttgart Ground Services GmbH
								<br />
								Postfach 23 04 11
								<br />
								70624 Stuttgart
							</p>
						</li>
						<li>
							<p>
								<strong>
									Bei Feedback/Fragen zu Sicherheitsthemen (z.B.:
									Sicherheitskontrolle, Passkontrolle):
								</strong>
								<br />
								Zur Aufklärung des Sachverhalts erfolgt eine Übermittlung Ihrer
								personenbezogenen Daten und Ihrer Anfrage gegebenenfalls an:
							</p>
							<p style="font-style: italic;">
								Bundespolizeidirektion Stuttgart
								<br />
								Wolfgang-Brumme-Allee 52
								<br />
								71034 Böblingen
							</p>
							<p>Und/oder an:</p>
							<p style="font-style: italic;">
								Bundespolizeiinspektion am Flughafen Stuttgart
								<br />
								70629 Stuttgart
							</p>
							<p>Und/oder an:</p>
							<p style="font-style: italic;">
								Polizeipräsidium Reutlingen
								<br />
								Kaiserstraße 99
								<br />
								72764 Reutlingen
							</p>
							<p>Und/oder an:</p>
							<p style="font-style: italic;">
								Polizeirevier Flughafen Stuttgart
								<br />
								Flughafenstraße 36
								<br />
								70629 Stuttgart
							</p>
							<p>Und/oder an:</p>
							<p style="font-style: italic;">
								Zollamt Flughafen Stuttgart
								<br />
								Flughafen Stuttgart
								<br />
								70629 Stuttgart
							</p>
						</li>
						<li>
							<p>
								<strong>
									Bei Feedback/Fragen zum Check-In und Boarding von Lufthansa,
									Austrian Airlines und Swiss-Air, sowie Fragen zur Lufthansa Lounge:
								</strong>
								<br />
								Zur Aufklärung des Sachverhalts erfolgt eine Übermittlung Ihrer
								personenbezogenen Daten und Ihrer Anfrage gegebenenfalls an:
							</p>
							<p style="font-style: italic;">
								Deutsche Lufthansa AG
								<br />
								Stationsleitung am Flughafen Stuttgart
								<br />
								70629 Stuttgart
							</p>
						</li>
						<li>
							<p>
								<strong>Bei Feedback/Fragen zur Elli-Beinhorn-Lounge:</strong>
								<br />
								Zur Aufklärung des Sachverhalts erfolgt eine Übermittlung Ihrer
								personenbezogenen Daten und Ihrer Anfrage gegebenenfalls an:
							</p>
							<p style="font-style: italic;">
								ChillOutLounges GmbH
								<br />
								Postfach 420 131
								<br />
								30662 Hannover
							</p>
							<p> </p>
						</li>
						<li>
							<p>
								<strong>Bei Feedback/Fragen zur Gastronomie/Shops:</strong>
								<br />
								Zur Aufklärung des Sachverhalts erfolgt eine Übermittlung Ihrer
								personenbezogenen Daten und Ihrer Anfrage gegebenenfalls an aktuell
								auf dem Campus des Flughafen Stuttgarts vorhandene Mieter oder
								Gastronomen.
							</p>
						</li>
						<li>
							<p>
								<strong>Bei Fragen zum Thema Lärmschutz:</strong>
								<br />
								Es erfolgt eine direkte Weiterleitung Ihrer personenbezogenen Daten
								und Ihrer Anfrage an den Lärmschutzbeauftragten des Flughafen
								Stuttgarts. Wenn Sie mit dem Lärmschutzbeauftragten des Flughafen
								Stuttgarts in Kontakt treten um Fluglärm zu melden, ist zusätzlich
								ist die Angabe Ihrer PLZ und Ihres Wohnorts erforderlich, damit der
								Lärmschutzbeauftragte des Flughafen Stuttgarts dem gemeldeten
								Fluglärm adäquat nachgehen und Ihr Anliegen sachgerecht beantworten
								kann.
							</p>
							<p style="font-style: italic;">
								Lärmschutzbeauftragter für den Flughafen Stuttgart
								<br />
								Regierungspräsidium Stuttgart
								<br />
								Ruppmannstraße 21
								<br />
								70565 Stuttgart
							</p>
						</li>
					</ul>
					<p>
						Wenn Sie uns per E-Mail kontaktieren oder per Kontaktformular
						kontaktieren, ist Rechtsgrundlage für die Datenverarbeitung jeweils Art.
						6 Abs. 1 lit. f DS-GVO sowie Art. 6 Abs. 1 lit. a DS-GVO.
					</p>
					<p>
						Unabhängig davon, ob Sie mit uns per E-Mail, oder mittels Kontaktformular
						in Kontakt treten, dienen Ihre Daten ausschließlich dazu, Ihre Anfrage
						sachgerecht zu bearbeiten und zu beantworten. Das gilt auch im Falle
						einer eventuellen Übermittlung Ihrer Daten an Dritte.
					</p>
					<p>
						Die Speicherung der personenbezogenen Daten erfolgt nur so lange, wie
						dies zur Erreichung des oben genannten Zwecks erforderlich ist oder gemäß
						der gesetzlich vorgesehenen Aufbewahrungsfristen.
					</p>
					<p>
						Das ist bei den personenbezogenen Daten, die Sie uns im Rahmen einer
						Anfrage per E-Mail oder per Kontaktformular übermitteln dann der Fall,
						wenn die jeweilige Konversation mit dem Nutzer beendet ist, also wenn der
						jeweilige Sachverhalt abschließend geklärt wurde.
					</p>
					<p>
						Die Datenverarbeitung erfolgt ausschließlich dazu, um Ihre Kontaktanfrage
						angemessen und abschließend beantworten zu können. Darin liegt
						gleichzeitig unser berechtigtes Interesse, welches wir Ihnen als Nutzer
						gemäß Art. 13 Abs. 1 lit. d DS-GVO hiermit mitteilen.
					</p>
				</li>
				<li id="4-5">
					<strong>
						<strong>
							Newsletter
							<br />
							<br />
						</strong>
					</strong>
					Die Flughafen Stuttgart GmbH bietet für spezielle Nutzergruppen
					(Reisebüropartner, Geschäftspartner/Corporates und Presseangehörige) die
					Möglichkeit, sich für einen zielgruppenorientierten Newsletter anzumelden.
					Mit diesen Newslettern übermitteln wir per E-Mail regelmäßig
					zielgruppenrelevante Informationen/Einladungen rund um die Flughafen
					Stuttgart GmbH.
					<p>
						Rechtsgrundlage für die Verarbeitung der Daten nach Anmeldung zum
						Newsletter durch den Nutzer ist Art. 6 Abs. 1 lit. a DS-GVO. Die Erhebung
						Ihrer personenbezogenen Daten dient zur Verifizierung und korrekten
						Zuordnung zum zielgruppenorientierten Newsletter und um sicherzustellen,
						dass der Newsletter zugestellt werden kann. Ihre Daten werden
						ausschließlich zum Versand des Newsletters verwendet.
					</p>
					<p>
						Die Speicherung Ihrer personenbezogenen Daten erfolgt nur so lange, wie
						dies zur Erreichung des oben genannten Zwecks erforderlich ist oder gemäß
						der gesetzlich vorgesehenen Aufbewahrungsfristen. Ihre Daten speichern
						wir so lange, wie das Abonnement Ihres Newsletters aktiv ist. Sie als
						Nutzer können das Abonnement des jeweiligen Newsletters jederzeit und
						ohne Angaben von Gründen kündigen, indem Sie Ihre erteilte Einwilligung
						widerrufen.
					</p>
					<p>
						Sie können hierzu jederzeit eine E-Mail an{" "}
						<a href="mailto:Betroffenenrechte@stuttgart-airport.com">
							Betroffenenrechte@stuttgart-airport.com
						</a>{" "}
						senden oder den "Abmelden" Button betätigen, den Sie im Newsletter
						finden.
					</p>
					<ol>
						<ol>
							<li style="list-style-type: lower-latin;">
								<p>
									<strong>
										Newsletter für Reisebüropartner, Geschäftspartner/Corporates{" "}
									</strong>
									<br />
									<br />
									Soweit Sie der Nutzergruppe Reisebüropartner, Geschäftspartner
									angehören und Interesse an einer Anmeldung zum Newsletter
									"Reisebüropartner, Geschäftspartner/Corporates" haben, wenden Sie
									sich bitte an{" "}
									<a href="mailto:Marketing@stuttgart-airport.com">
										Marketing@stuttgart-airport.com
									</a>
									. Damit die Anmeldung berücksichtigt werden kann, benötigen wir
									Informationen zu Ihrer Person (E-Mail-Adresse, Name) und der
									Organisation/dem Unternehmen, dem Sie angehören. Für die
									Kontaktaufnahme per E-Mail gilt <a href="#4-4">Ziffer 4</a> dieser
									Datenschutzerklärung.
								</p>
								<p>
									Zum Versand des Newsletters für Reisebüropartner,
									Geschäftspartner/Corporates wird die Newsletter Software
									"Newsletter2Go" verwendet. Ihre Daten werden dabei an die
									Newsletter2Go GmbH übermittelt. Newsletter2Go ist es untersagt,
									Ihre Daten zu verkaufen, oder für andere Zwecke, als für den
									Versand des Newsletters zu nutzen. Newsletter2Go ist ein deutscher,
									zertifizierter Anbieter welcher nach den Anforderungen der DS-GVO
									ausgewählt wurde.
								</p>
								<p>
									Weitere Informationen finden Sie hier:
									<br />
									<a
										rel="noopener"
										href="https://www.newsletter2go.de/informationen-newsletter-empfaenger"
										target="_blank"
									>
										https://www.newsletter2go.de/informationen-newsletter-empfaenger
									</a>
								</p>
								<p>
									Rechtsgrundlage der Übermittlung an Newsletter2Go ist Art. 6 Abs. 1
									lit. f DS-GVO. Die Nutzung des Versanddienstleisters erfolgt, um
									einen reibungslosen technischen Versand der Newsletter zu
									gewährleisten und um die informationstechnische Integrität der
									Server der Flughafen Stuttgart GmbH zu gewährleisten. Darin liegt
									gleichzeitig unser berechtigtes Interesse, welches wir Ihnen als
									Nutzer gemäß Art. 13 Abs. 1 lit. d DS-GVO hiermit mitteilen.
								</p>
							</li>
							<li style="list-style-type: lower-latin;">
								<p>
									<strong>
										Newsletter für Presseangehörige ("Pressenewsletter")
									</strong>
									<br />
									<br />
									Soweit Sie der Nutzergruppe Presseangehörige angehören und
									Interesse an einer Anmeldung zum Newsletter "Presserundschreiben"
									haben, wenden Sie sich bitte an{" "}
									<a href="mailto:Presse@stuttgart-airport.com">
										Presse@stuttgart-airport.com
									</a>
									. Damit die Anmeldung berücksichtigt werden kann, benötigen wir
									Informationen zu Ihrer Person (E-Mail-Adresse, Name) und der
									Organisation/dem Unternehmen, dem Sie angehören. Für die
									Kontaktaufnahme per E-Mail gilt <a href="#4-4">Ziffer 4</a> dieser
									Datenschutzerklärung.
								</p>
							</li>
							<li style="list-style-type: lower-latin;">
								<p>
									<strong>
										Informationssendung an politische Stakeholder ("Politikbrief")
									</strong>
									<br />
									<br />
									Soweit Sie der Nutzergruppe politische Stakeholder der Flughafen
									Stuttgart GmbH angehören und Interesse an einer Anmeldung zum
									Politikbrief haben, wenden Sie sich bitte an{" "}
									<a href="mailto:politikbrief@stuttgart-airport.com">
										politikbrief@stuttgart-airport.com
									</a>
									. Der Politikbrief informiert Sie regelmäßig (circa einmal pro
									Quartal) über die wichtigsten Entwicklungen im Luftverkehr am
									Standort Stuttgart. Die Publikation bietet Hintergründe,
									Standpunkte, Daten und Fakten für Ihre politische Arbeit. Damit die
									Anmeldung berücksichtigt werden kann, benötigen wir Informationen
									zu Ihrer Person (E-Mail-Adresse, Name), möchten Sie den
									Politikbrief per Post erhalten, benötigen wir auch Ihre
									Postadresse. Für die Kontaktaufnahme per E-Mail gilt Ziffer 4
									dieser Datenschutzerklärung.
								</p>
							</li>
						</ol>
					</ol>
				</li>
				<li id="4-6">
					<strong>
						Social-Media Profile und Kommentarfunktionen auf unserer Webseite
						<br />
					</strong>
					<br />
					<ol>
						<li style="list-style-type: lower-latin;">
							<p>
								<strong>Social-Media Profile</strong>
								<br />
								<br />
								Die Flughafen Stuttgart GmbH unterhält Profile in verschiedenen
								sozialen Netzwerken. Zu diesen Profilen finden Sie Links auf unserer
								Webseite. Konkret unterhält die Flughafen Stuttgart GmbH folgende
								Profile:
							</p>
							<ul>
								<li>
									<a
										rel="noopener"
										href="https://www.facebook.com/FlughafenStuttgart"
										target="_blank"
									>
										​https://www.facebook.com/FlughafenStuttgart
									</a>
								</li>
								<li>
									<a
										rel="noopener"
										href="https://www.instagram.com/Stuttgartairport"
										target="_blank"
									>
										https://www.instagram.com/Stuttgartairport
									</a>
								</li>
								<li>
									<a
										rel="noopener"
										href="https://www.snapchat.com/add/str_flughafen"
										target="_blank"
									>
										https://www.snapchat.com/add/str_flughafen
									</a>
								</li>
								<li>
									<a
										rel="noopener"
										href="https://www.youtube.com/user/StuttgartAirport"
										target="_blank"
									>
										https://www.youtube.com/user/StuttgartAirport
									</a>
								</li>
								<li>
									<a
										rel="noopener"
										href="https://www.xing.com/companies/flughafenstuttgartgmbh"
										target="_blank"
									>
										https://www.xing.com/companies/flughafenstuttgartgmbh
									</a>
								</li>
							</ul>
							<p>
								Wir freuen uns über Ihren Besuch auf einem unserer Profile in den
								oben genannten Netzwerken. In diesem Fall gelten die
								Geschäftsbedingungen und Datenschutzbestimmungen der jeweiligen
								Betreiber.
								<br />
								<br />
								Alle Verweise zu Social-Media Profilen auf unserer Webseite sind
								mittels einer Verlinkung eingebunden. Personenbezogen Daten werden
								daher ohne Ihr aktives Zutun nicht an Dritte übermittelt.
								<br />
								<br />
								Eine Übermittlung findet nur dann statt, wenn Sie diese durch Klicken
								des Links selbst veranlassen. In diesem Fall gelten die
								Datenschutzbestimmungen des jeweiligen sozialen Netzwerks. Ihre
								diesbezüglichen Rechte als Nutzer und Einstellungsmöglichkeiten zum
								Schutz Ihrer Privatsphäre können Sie den Datenschutzhinweisen der
								Anbieter entnehmen.
							</p>
							<ul>
								<li>
									<a
										rel="noopener"
										href="https://www.facebook.com/policy.php"
										target="_blank"
									>
										https://www.facebook.com/policy.php
										<br />
									</a>
								</li>
								<li>
									<a
										rel="noopener"
										href="https://twitter.com/privacy"
										target="_blank"
									>
										https://twitter.com/privacy
										<br />
									</a>
								</li>
								<li>
									<a
										rel="noopener"
										href="https://help.instagram.com/155833707900388"
										target="_blank"
									>
										https://help.instagram.com/155833707900388
										<br />
									</a>
								</li>
								<li>
									<a
										rel="noopener"
										href="https://www.snap.com/en-US/privacy/privacy-policy"
										target="_blank"
									>
										https://www.snap.com/en-US/privacy/privacy-policy
										<br />
									</a>
								</li>
								<li>
									<a
										rel="noopener"
										href="https://www.youtube.com/static?template=privacy_guidelines"
										target="_blank"
									>
										https://www.youtube.com/static?template=privacy_guidelines
										<br />
									</a>
								</li>
								<li>
									<a
										rel="noopener"
										href="https://privacy.xing.com/de/datenschutzerklaerung"
										target="_blank"
									>
										https://privacy.xing.com/de/datenschutzerklaerung
									</a>
								</li>
							</ul>
						</li>
						<li style="list-style-type: lower-latin;">
							<p>
								<strong>Aktivierung von DISQUS in unserem Blog</strong>
							</p>
							<p>
								In unserem Blog beschreiben verschiedene Autoren ihre persönliche
								Sicht auf ein Thema und berichten aus subjektiver Sicht über Themen
								rund um den Flughafen Stuttgart. Leser können diese Beiträge
								kommentieren. Hierzu setzen wir den Kommentardienst DISQUS, angeboten
								von der Firma DISQUS Inc., 301 Howard Street, Suite 300, San
								Francisco, CA 94105, USA ("DISQUS") ein.
							</p>
							<p>
								Diese Kommentarfunktion ist standardmäßig deaktiviert. Um die
								Kommentarfunktion DIQUS benutzen zu können, muss diese zunächst durch
								den Nutzer aktiviert werden. Dies geschieht mittels Setzen eines
								entsprechenden Cookies. Wenn Sie die DISQUS Kommentarfunktion nicht
								aktivieren, werden keine personenbezogenen Daten an DISQUS
								übermittelt.
							</p>
							<p>
								Das Aktivieren von DISQUS geschieht auf unserer Webseite mittels
								eines Klicks auf den Button "DISQUS (Kommentare) aktivieren". Danach
								können Sie sich über ein bestehendes DISQUS-Konto oder über ein
								bestehendes Social-Media-Konto (zum Beispiel Facebook) bei DIQUS
								anmelden und Kommentare verfassen. Sie können die DISQUS Funktion
								zudem auch als Gast, ohne Erstellung eines Kontos bei DISQUS und ohne
								Erstellung/Anmeldung eines Social-Media-Kontos nutzen. Wenn Sie die
								Kommentarfunktion DISQUS aktivieren und/oder Kommentare verfassen,
								werden personenbezogene Daten, wie zum Beispiel:
							</p>
							<ul>
								<li>Ihre IP-Adresse,</li>
								<li>Zeitpunkt des Kommentars,</li>
								<li>
									Inhalt des Kommentars (z.B. auch Name, E-Mail-Adresse des
									Kommentarerstellers)
								</li>
							</ul>
							<p>
								an DISQUS und somit an Server in Drittländern übermittelt. DISQUS und
								seine Funktion wird auf unserer Webseite nur eingebettet. Die Nutzung
								der Kommentarfunktion erfolgt daher nach den Bedingungen von DISQUS
								und ausschließlich im Verhältnis zwischen Ihnen als Nutzer und DISQUS
								selbst. Bitte beachten Sie daher die Datenschutzhinweise (
								<a
									rel="noopener"
									href="https://help.disqus.com/terms-and-policies/diqus-privacy-policy"
									target="_blank"
								>
									https://help.disqus.com/terms-and-policies/diqus-privacy-policy
								</a>
								) und die Nutzungsbedingungen von DISQUS (
								<a
									rel="noopener"
									href="https://help.disqus.com/terms-and-policies/terms-of-service"
									target="_blank"
								>
									https://help.disqus.com/terms-and-policies/terms-of-service
								</a>
								). Ansprechpartner für die Löschung der Daten ist ausschließlich
								DISQUS.
								<br />
								Soweit Sie als Nutzer die Kommentarfunktion von DISQUS aktivieren
								erfolgt der Einsatz von DISQUS und die damit einhergehende
								Übermittlung personenbezogener Daten an DISQUS auf der
								Rechtsgrundlage des Art. 6 Abs. 1 lit. f DS-GVO.
							</p>
							<p>
								Die Verarbeitung der personenbezogenen Daten erfolgt zum Zwecke einer
								effizienten und nutzerfreundlichen Gestaltung der
								Kommentarverwaltung.
							</p>
							<p>
								Darin liegt gleichzeitig unser berechtigtes Interesse, welches Ihnen
								als Nutzer gemäß Art. 13 Abs. 1 lit. d DS-GVO hiermit mitteilen.
							</p>
							<p>
								DISQUS ist nach dem Privacy-Shield-Abkommen zertifiziert und bietet
								hierdurch eine Garantie, europäisches Datenschutzrecht und somit die
								DS-GVO, einzuhalten.
							</p>
							<p>
								Den Link zur Datenschutzerklärung finden Sie hier:{" "}
								<a
									rel="noopener"
									href="https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active"
									target="_blank"
								>
									https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active
								</a>
							</p>
							<p>
								Soweit eine Übermittlung von personenbezogenen Daten an DISQUS nicht
								erwünscht ist, empfehlen wir Ihnen, die Kommentarfunktion nicht zu
								aktivieren.
							</p>
						</li>
					</ol>
				</li>
				<li id="4-7">
					<strong>
						<strong>
							Flugstatusbenachrichtigung per E-Mail
							<br />
						</strong>
					</strong>
					<p>
						Auf unserer Webseite haben Sie als Nutzer die Möglichkeit, sich per
						E-Mail über Statusupdates von Flügen, die Sie selbst auswählen können,
						benachrichtigen zu lassen.
					</p>
					<p>
						Dazu können Sie als Nutzer den jeweiligen Flugstatus (z.B. Status:
						Unterwegs; Status: Gelandet; Status: Boarding) und Flugstatusänderungen
						eines bestimmten Fluges per E-Mail abonnieren. Zu diesem Zweck erheben
						wir in der Anmeldemaske zur Benachrichtigung Ihre E-Mail-Adresse. Die
						Erhebung dieser Daten erfolgt ausschließlich dazu, Ihnen
						Flugstatusänderungen des gewünschten Flugs bzw. der gewünschten Flüge
						mitzuteilen.
					</p>
					<p>
						Die Anmeldung zur Flugstatusbenachrichtigung erfolgt im Wege des
						sogenannten Double-Opt-In Verfahrens. Das bedeutet, dass Sie nach der
						Anmeldung eine E-Mail erhalten, in der Sie um Bestätigung Ihrer Anmeldung
						zur Flugstatusbenachrichtigung durch Klicken eines Links gebeten werden.
						Nur wenn diese Bestätigung erfolgt, erhalten Sie die gewünschten
						Flugstatusbenachrichtigungen und Flugstatusänderungen per E-Mail. Im Zuge
						des Double-Opt-In Verfahrens werden folgende Daten gespeichert:
					</p>
					<ul>
						<li>E-Mail-Adresse</li>
						<li>IP-Adresse im Rahmen des Server-Logs</li>
						<li>Zeitpunkt der Bestätigung</li>
					</ul>
					Rechtsgrundlage dieser Datenverarbeitung ist Art. 6 Abs. 1 lit. a DS-GVO.{" "}
					<br />
					<br />
					Die Speicherung der personenbezogenen Daten erfolgt nur so lange, wie dies
					zur Erreichung des oben genannten Zwecks erforderlich ist oder gemäß der
					gesetzlich vorgesehenen Aufbewahrungsfristen. Ihre E-Mail-Adresse wird in
					der Regel nach einer Frist von 4 Tagen, spätestens jedoch nach 7 Tagen,
					nach Beendigung des jeweiligen Flugs, gelöscht.
					<br />
					<br />
				</li>
				<li>
					<strong>
						Buchungsmöglichkeiten und sonstige Funktionen unserer Webseite
					</strong>
					<br />
					<br />
					<ol>
						<li style="list-style-type: lower-latin;">
							<p>
								<strong>Buchung von VIP-Services</strong>
								<br />
								Auf unserer Webseite haben sie die Möglichkeit, VIP-Services rund um
								Ihren Flug zu buchen. Hierbei handelt es sich um spezielle
								Dienstleistungen (z.B. Shuttle-Service).
							</p>
							<p>
								Zu diesem Zweck finden Sie eine Buchungsmaske auf unserer Webseite.
								Je nachdem welches VIP-Paket Sie buchen, fragen wir verschiedene
								Daten ab, die zur Buchung des jeweiligen Pakets erforderlich sind
								(z.B. Ihren Namen, Anzahl der Mitreisenden Erwachsenen und Kinder,
								Flugdaten). Da die Durchführung der bestellten Leistung auf einem
								internationalen Verkehrsflughafen stattfindet und somit gesetzlichen
								Sicherheitsanforderungen unterliegt, werden wir Ihre Daten im Rahmen
								der Vertragsdurchführung an Dritte weitergeben, soweit das
								erforderlich ist. Dritte in diesem Sinne sind die involvierten
								Behörden (vgl. <a href="#4-0">Ziffer 4</a>) sowie die zur
								Vertragsdurchführung erforderlichen internen Stellen (z.B.
								Terminalaufsicht, Verkehrsleiter vom Dienst) und externen
								Kooperationspartner (z.B. die gebuchte Airline, der für diese Airline
								zuständige Handling-Partner, der an der Personal- und Warenkontrolle
								eingesetzte Sicherheitsdienstleister). Rechtsgrundlage dieser
								Datenverarbeitung ist Art. 6 Abs. 1 lit. b DS-GVO. Die
								Datenverarbeitung erfolgt ausschließlich zum Zweck der
								Vertragsdurchführung. <br />
								Die Speicherung der personenbezogenen Daten erfolgt nur so lange, wie
								dies zur Erreichung des oben genannten Zwecks erforderlich ist oder
								gemäß der gesetzlich vorgesehenen Aufbewahrungsfristen.
							</p>
						</li>
						<li style="list-style-type: lower-latin;">
							<p>
								<strong>Buchung von Flughafenführungen</strong>
								<br />
								Auf unserer Webseite haben Sie die Möglichkeit, Flughafenführungen zu
								buchen. Hierzu stellen wir Ihnen eine Plattform für Ihre
								Buchungsanfrage/Buchung zur Verfügung. Im Rahmen der
								Buchungsanfrage/Buchung erheben wir die zur Durchführung der Führung
								notwendigen Daten (Teilnehmeranzahl, Gruppenart, Anschrift der
								Ansprechperson/der buchenden Organisation bzw. des buchenden
								Unternehmens, Rechnungsadresse, Telefonnummer, E-Mail-Adresse). Im
								Anschluss an die Buchung erhalten Sie zunächst eine
								Eingangsbestätigung der Reservierungsanfrage und bei erfolgreicher
								Buchung eine Bestätigungsmail.
							</p>
							<p>
								Rechtsgrundlage dieser Datenverarbeitung ist Art. 6 Abs. 1 lit. b
								DS-GVO. Die anzugebenden Daten sind für die Durchführung und
								Abwicklung der Flughafenführung und Buchung erforderlich. Die
								Übertragung der Daten an uns erfolgt verschlüsselt im Rahmen der
								SSL-Verschlüsselung unserer Webseite.
							</p>
							<p>
								Die Speicherung der personenbezogenen Daten erfolgt nur so lange, wie
								dies zur Erreichung des oben genannten Zwecks erforderlich ist oder
								gemäß der gesetzlich vorgesehenen Aufbewahrungsfristen.
							</p>
						</li>
						<li style="list-style-type: lower-latin;">
							<p>
								<strong>Bewerbungsportal</strong>
								<br />
								<br />
								Sie haben die Möglichkeit, sich über das auf unserer Homepage
								integrierte Bewerbungsportal "d.vinci" der Firma d.vinci HR-Systems
								GmbH, Nagelsweg 37 – 39, 20097 Hamburg, bei uns online zu bewerben.
							</p>
							<p>
								Ihre dazu eingegebenen personenbezogenen Daten werden ausschließlich
								bei der d.vinci HR-Systems GmbH gespeichert. Rechtsgrundlage dieser
								Datenverarbeitung ist Art. 6 Abs. 1 lit. b DS-GVO.
							</p>
							<p>
								Die d.vinci HR-Systems GmbH wurde von uns sorgfältig ausgewählt. Es
								bestehen vertragliche Regelungen entsprechend den Voraussetzungen der
								DS-GVO (Art. 28 DS-GVO).
							</p>
						</li>
					</ol>
				</li>
				<li id="4-9">
					<strong>
						Abonnement des Airport-Magazins Flugblatt
						<br />
					</strong>
					<p>
						Auf unserer Webseite haben Sie die Möglichkeit, sich portofrei für ein
						Abonnement unseres Airport-Magazins „Flugblatt“ anzumelden. Wenn Sie das
						Airport-Magazin erhalten möchten, schreiben Sie bitte eine E-Mail mit
						Ihrem Namen und Ihrer vollständigen Adresse an{" "}
						<a href="mailto:publikationen@stuttgart-airport.com">
							publikationen@stuttgart-airport.com
						</a>
						.
					</p>
					<p>
						Wenn Sie sich für den Versand des Airport-Magazins anmelden, werden Ihre
						personenbezogenen Daten ausschließlich zum Zweck des Versands des
						Flugblatts verarbeitet. Ihre personenbezogenen Daten sind dazu
						erforderlich, um Ihnen das unser Airport-Magazin zuzustellen und somit um
						das von Ihnen gewünschte Abonnement zu bedienen.
					</p>
					<p>
						Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten ist
						Art. 6 Abs. 1 lit. a DS-GVO. Ihre personenbezogenen Daten werden nur
						solange gespeichert, wie es für die Durchführung des Abonnements
						erforderlich ist. Ihre Daten speichern wir also so lange, wie Ihr
						Abonnement des Flugblatts aktiv ist. Eine Weitergabe Ihrer
						personenbezogenen Daten erfolgt an den Versanddienstleister zum Zwecke
						des Versands des Flugblatts an Sie. Eine Weitergabe Ihrer
						personenbezogenen Daten an weitere Dritte erfolgt nicht.
					</p>
					<p>
						Das Abonnement des Airport-Magazins kann jederzeit und ohne Angabe von
						Gründen gekündigt werden, indem Sie Ihre erteilte Einwilligung
						widerrufen.
					</p>
					<p>
						Sie können hierzu jederzeit eine E-Mail an
						Betroffenenrechte@stuttgart-airport.com senden.
					</p>
					<p>
						Für die Kontaktaufnahme per E-Mail gilt{" "}
						<a href="#4-4">Abschnitt IV Ziffer 4</a> dieser Datenschutzerklärung.
					</p>
				</li>
			</ol>
			<h2 id="5-0" style="margin: 30px 0 6px 0;">
				V   Betroffenenrechte
			</h2>
			<p>
				Werden personenbezogene Daten von uns verarbeitet, sind Sie Betroffener im
				Sinne der DS-GVO. Ihnen stehen daher Rechte gegenüber der Flughafen Stuttgart
				GmbH als Verantwortliche zu. Soweit Sie ein solches Recht geltend machen
				möchten, wenden Sie sich bitte an:
			</p>
			<p>
				Flughafen Stuttgart GmbH
				<br />
				Flughafenstraße 32
				<br />
				70629 Stuttgart
				<br />
				E-Mail:{" "}
				<a href="mailto:Betroffenenrechte@stuttgart-airport.com">
					Betroffenenrechte@stuttgart-airport.com
				</a>
			</p>
			<p>Ihnen stehen nachfolgende Rechte zu:</p>
			<ol>
				<li id="5-1">
					<p>
						<strong>Auskunftsrecht gemäß Art. 15 DS-GVO</strong>
						<br />
						<br />
						Sie haben gemäß Art. 15 DS-GVO das Recht, Auskunft über Ihre von uns
						verarbeiteten personenbezogenen Daten zu verlangen. Sie können
						insbesondere Auskunft über die Verarbeitungszwecke, die Kategorie der
						personenbezogenen Daten, die Kategorien der Empfänger, gegenüber denen
						Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer,
						das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der
						Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die
						Herkunft Ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie
						über das Bestehen einer automatisierten Entscheidungsfindung
						einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren
						Einzelheiten verlangen.
					</p>
					<p>
						Ihnen steht zudem das Recht zu, Auskunft darüber zu verlangen, ob Ihre
						personenbezogenen Daten in ein Drittland oder an eine internationale
						Organisation übermittelt werden. In diesem Zusammenhang können Sie
						verlangen, über die geeigneten Garantien gem. Art. 46 DS-GVO im
						Zusammenhang mit der Übermittlung unterrichtet zu werden.
					</p>
				</li>
				<li id="5-2">
					<p>
						<strong>Recht auf Berichtigung gemäß Art. 16 DS-GVO</strong>
						<br />
						<br />
						Sie haben gemäß Art. 16 DS-GVO ein Recht auf Berichtigung und/oder
						Vervollständigung Ihrer personenbezogenen Daten gegenüber uns als
						Verantwortlicher, sofern die verarbeiteten personenbezogenen Daten, die
						Sie betreffen, unrichtig oder unvollständig sind. Wir als Verantwortliche
						müssen diese Berichtigung unverzüglich vornehmen.
					</p>
				</li>
				<li id="5-3">
					<p>
						<strong>
							Recht auf Löschung ("Vergessenwerden") gemäß Art. 17 DS-GVO
						</strong>
						<br />
						<br />
						Sie haben gemäß Art. 17 Abs. 1 DS-GVO das Recht, die Löschung Ihrer bei
						uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die
						Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und
						Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen
						des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
						Verteidigung von Rechtsansprüchen erforderlich ist (vgl. Art. 17 Abs. 3
						DS-GVO).
					</p>
					<p>
						Haben wir als Verantwortliche die Sie betreffenden personenbezogenen
						Daten öffentlich gemacht und sind wir gemäß Art. 17 Abs. 1 DS-GVO zur
						Löschung dieser personenbezogenen Daten verpflichtet, so werden wir unter
						Berücksichtigung der verfügbaren Technologie und der
						Implementierungskosten angemessene Maßnahmen, auch technischer Art,
						treffen, um andere, für die Datenverarbeitung Verantwortliche, die die
						personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als
						betroffene Person von ihnen die Löschung aller Links zu diesen
						personenbezogenen Daten oder von Kopien oder Replikationen dieser
						personenbezogenen Daten verlangt haben.
					</p>
				</li>
				<li id="5-4">
					<p>
						<strong>
							Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DS-GVO
						</strong>
						<br />
						<br />
						Sie haben gemäß Art. 18 DS-GVO das Recht, die Einschränkung der
						Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die
						Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung
						unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten
						nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder
						Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DS-GVO
						Widerspruch gegen die Verarbeitung eingelegt haben.
					</p>
				</li>
				<li id="5-5">
					<p>
						<strong>Recht auf Datenübertragbarkeit gemäß Art. 20 DS-GVO</strong>
						<br />
						<br />
						Sie haben gemäß Art. 20 DS-GVO das Recht, Ihre personenbezogenen Daten,
						die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
						maschinenlesbaren Format zu erhalten oder die Übermittlung an einen
						anderen Verantwortlichen zu verlangen.
					</p>
				</li>
				<li id="5-6">
					<p>
						<strong>Widerspruchsrecht gemäß Art. 21. DS-GVO</strong>
						<br />
						<br />
						Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation
						ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
						personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f
						DS-GVO erfolgt, Widerspruch einzulegen.
					</p>
					<p>
						Wir als Verantwortliche werden die Sie betreffenden personenbezogenen
						Daten dann nicht mehr verarbeiten, es sei denn wir können zwingende
						schutzwürdige Gründe für die Verarbeitung nachweisen, die gegenüber Ihren
						Interessen, Rechten und Freiheiten überwiegen, oder die Verarbeitung
						dient der Geltendmachung, Ausübung oder Verteidigung von
						Rechtsansprüchen. Werden die Sie betreffenden personenbezogenen Daten
						verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht,
						jederzeit Widerspruch gegen die Verarbeitung einzulegen. Widersprechen
						Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie
						betreffenden personenbezogenen Daten nicht mehr für diese Zwecke
						verarbeitet.
					</p>
				</li>
				<li id="5-7">
					<p>
						<strong>
							Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
							gemäß Art. 7 Abs. 3 DS-GVO
						</strong>
						<br />
						<br />
						Sie haben das Recht, Ihre datenschutzrechtliche Einwilligung jederzeit zu
						widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit
						der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
						nicht berührt.
					</p>
				</li>
				<li id="5-8">
					<p>
						<strong>
							Automatisierte Entscheidung im Einzelfall einschließlich Profiling
							gemäß Art. 22 DS-GVO
						</strong>
						<br />
						<br />
						Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten
						Verarbeitung – einschließlich Profiling – beruhenden Entscheidung
						unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet
						oder Sie in ähnlicher Weise erheblich beeinträchtigt.
					</p>
				</li>
				<li id="5-9">
					<p>
						<strong>
							Recht auf Beschwerde bei einer Aufsichtsbehörde gemäß Art. 77 DS-GVO
						</strong>
						<br />
						<br />
						Sie haben gemäß Art. 77 DS-GVO das Recht, sich bei einer Aufsichtsbehörde
						zu beschweren. Hierzu können Sie sich in der Regel an die
						Aufsichtsbehörde Ihres Aufenthaltsortes, Arbeitsplatzes oder unseres
						Unternehmenssitzes wenden.
					</p>
					<p>
						Die für die Flughafen Stuttgart GmbH zuständige Aufsichtsbehörde ist:
					</p>
					<p>
						Der Landesbeauftragte für den Datenschutz und die Informationsfreiheit
						<br />
						Postfach 10 29 32
						<br />
						70025 Stuttgart
					</p>
					<p>
						Telefon: 0711/615541-0
						<br />
						Fax: 0711/615541-15
						<br />
						E-Mail:{" "}
						<a href="mailto:Poststelle@lfdi.bwl.de">Poststelle@lfdi.bwl.de</a>
					</p>
				</li>
			</ol>
			<h2 id="6-0" style="margin: 30px 0 6px 0;">
				VI   Datensicherheit
			</h2>
			<p>
				Wir verwenden auf unserer Webseite eine Verschlüsselung in Form des
				SSL-Verfahrens (Secure Socket Layer) mit einer 256-Bit Verschlüsselung. Die
				Verschlüsselung unserer Webseite und deren einzelner Bereiche erkennen Sie am
				Schloss bzw. Schlüssel Symbol in der unteren Statusleiste Ihres Browsers.
			</p>
			<p>
				Grundsätzlich bedienen wir uns geeigneten technischen und organisatorischen
				Maßnahmen im Sinne des Art. 32 DS-GVO um Ihre Daten gegen zufällige oder
				vorsätzliche Manipulation, teilweisen oder vollständigen Verlust, Zerstörung
				oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere
				Sicherheitsmaßnahmen werden, entsprechend dem technischen Fortschritt und der
				technischen Entwicklung stetig weiterentwickelt und verbessert.
			</p>
			<h2 id="7-0" style="margin: 30px 0 6px 0;">
				VII   Rückfragen
			</h2>
			<p>
				Bei Fragen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten durch
				uns und bei Fragen zu Ihren Rechten als betroffene Person, können Sie sich
				jederzeit an unseren betrieblichen Datenschutzbeauftragten wenden. Auch wenn
				Sie Anregungen oder sonstige Hinweise zum Thema Datenschutz haben, können Sie
				sich jederzeit an unseren betrieblichen Datenschutzbeauftragten wenden. Die
				Kontaktdaten finden Sie unter II. dieser Datenschutzerklärung.
			</p>
			<h2 id="8-0" style="margin: 30px 0 6px 0;">
				VIII   Stand dieser Datenschutzerklärung
			</h2>
			<p>
				Diese Datenschutzerklärung ist aktuell gültig und befindet sich auf dem Stand
				vom 25.05.2018.
			</p>`,
				}}
			/>

			<Footer suffix="de" />
		</div>
	)
}
