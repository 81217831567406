import * as React from "react"
import upIcon from "icons/svg/012_pfeil_oben_weiss.svg"
import twitterIcon from "icons/svg/fsg_social_media_002_twitter_schwarz.svg"
import facebookIcon from "icons/svg/fsg_social_media_001_facebook_schwarz.svg"

interface FooterProps {
	suffix: string
}
export default ({ suffix }: FooterProps): JSX.Element => {
	return (
		<>
			<footer className="section__footer">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="footer">
								<div className="row">
									<div className="col-12 col-lg-6">
										<nav>
											<ul className="list-group list-group-horizontal">
												<li className="px-2">
													<a href={`/imp-${suffix}`} className="link footer__link">
														{suffix === "de" ? `Impressum` : `Imprint`}
													</a>
												</li>
												<li className="px-2">
													<a href={`/dse-${suffix}`} className="link footer__link">
														{suffix === "de" ? `Datenschutz` : `Privacy Statement`}
													</a>
												</li>
												<li className="px-2">
													<a
														href="https://www.stuttgart-airport.com/agbs"
														target="_blank"
														className="link footer__link"
													>
														AGB
													</a>
												</li>
											</ul>
										</nav>
									</div>
									<div className="col-12 col-lg-6">
										<div className="footer__contacts list-group-horizontal-sm">
											<ul className="social__nav">
												<li className="px-2" />
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}
